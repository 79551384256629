body {
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

h1, h2 {
  margin: 0.5em 0;
}

p {
  margin: 0.5em 0;
}

.language-switcher {
  margin-bottom: 20px;
}

.language-switcher select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff;
  width: 80%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.upload-box {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  width: 100%;
}

.upload-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-content img {
  width: 50px;
  height: 50px;
}

.upload-content h2 {
  margin: 10px 0;
}

.upload-content p {
  margin: 5px 0;
}

.upload-content button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.upload-content button:hover {
  background-color: #0056b3;
}

.preview-section {
  margin-top: 20px;
  width: 100%;
  margin-left: 20px;
}

.uploaded-files {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  flex-direction: column;

}

.uploaded-file {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background-color: #f9f9f9;
  /* width: 150px; */
  text-align: center;
}

.uploaded-file img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 10px;
}

.uploaded-file p {
  margin: 5px 0;
  padding: 10px;
}

.action-buttons {
  margin-top: 20px;
  display: flex;
  gap: 100px;
}

.action-buttons button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.action-buttons button:hover {
  background-color: #218838;
}

.action-buttons button:first-child {
  background-color: #dc3545;
}

.action-buttons button:first-child:hover {
  background-color: #c82333;
}

.loading {
  margin-top: 20px;
  font-size: 1.2em;
  color: #007bff;
}

.result {
  margin-top: 20px;
  width: 100%;
}

.result-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
}

.result-item h3 {
  margin: 0 0 10px 0;
}

.result-item p {
  margin: 0 0 10px 0;
}

.result-item button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.result-item button:hover {
  background-color: #0056b3;
}
